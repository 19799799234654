<template>
  <div class="lg:w-1/2 w-full mb-5">
    <div class="flex space-x-3">
      <h1 class="text-2xl font-bold">
        {{ $t("business.support_documents.title") }}
      </h1>
    </div>
    <p
      class="mt-4"
      v-html="
        validate_support_documents
          ? this.business.business_status_id != BUSINESS_STATUS.APPROVED
            ? $t('business.support_documents.description_no_documents')
            : $t('business.support_documents.description_documents_approved')
          : $t('business.support_documents.description', {
              url: $router.resolve({ name: 'payment-settings' }).href,
            })
      "
    ></p>
  </div>
  <div v-if="!disabled">
    <div class="max-w-3xl" v-if="isHasBusinessSupportDocuments">
      <div
        class="space-y-5"
        v-show="showSelectPaymentMethod && !validate_support_documents"
      >
        <p class="mr-1">
          {{ $t("business.support_documents.preferred_payment_method") }}
        </p>
        <app-form-payment-method
          :filterPaymentMethods="[
            PAYMENT_METHODS.ONLINE_BANKING_B2C,
          ]"
          v-model="select_payment_method"
          :multiple="true"
        />
        <!-- Hide CC options -->
        <!-- <app-form-payment-method
          :filterPaymentMethods="[
            PAYMENT_METHODS.ONLINE_BANKING_B2C,
            PAYMENT_METHODS.CREDIT_CARD,
          ]"
          v-model="select_payment_method"
          :multiple="true"
        /> -->
        <app-button :disabled="!rule" @click="showSelectPaymentMethod = false">
          {{ $t("general.continue") }}
        </app-button>
      </div>
      <form
        @submit.prevent="submitSupportDocument"
        class="space-y-8"
        v-show="!showSelectPaymentMethod || validate_support_documents"
      >
        <!-- <div
          class="
            p-3
            flex
            rounded
            text-sm
            space-x-3
            items-center
            justify-between
            border border-gray-300
          "
          v-if="!validate_support_documents"
        >
          <div class="flex">
            <p class="mr-1">
              {{ $t("business.support_documents.preferred_payment_method") }}
            </p>
            <div
              v-for="(payment_method, index) in select_payment_method"
              :key="payment_method"
              class="font-semibold"
            >
              <span>{{ index > 0 ? ", " : "" }}</span>
              <span>{{ payment_method?.name }}</span>
            </div>
          </div>
          <p
            class="text-primary cursor-pointer"
            @click="showSelectPaymentMethod = true"
          >
            {{ $t("general.change") }}
          </p>
        </div> -->

        <app-upload-support-document
          v-for="(documents, name) in support_documents"
          :max="max"
          :key="name"
          :title="name"
          :errors="errors"
          @refresh="refresh"
          @changed="changed"
          :disabled="disabled"
          :documents="documents"
          @delete="deleteDocument"
          :type="type_support_documents"
          :rule="validate_support_documents ? 'all' : rule"
        />
        <p
          v-html="
            $t('business.support_documents.terms_and_conditions', {
              url: 'https://swipego.io/term-and-condition-payement-provider',
            })
          "
        />
        <app-button
          type="submit"
          :showf70Icon="true"
          :disabled="!unsaved && disabled"
          v-if="
            (select_payment_method || validate_support_documents) && !disabled
          "
          :loading="loading || mediaLoading"
        >
          {{ $t("general.continue") }}
        </app-button>
      </form>
    </div>
  </div>
  <div v-else>
    <div class="w-full space-y-5">
      <div v-for="(documents, name) in support_documents" :key="name">
        <p v-if="!$lodash.isEmpty(documents)" class="uppercase text-xs pb-3">
          {{ name?.split("_").join(" ") }}
        </p>
        <div
          v-for="(document, index) in documents"
          :key="index"
          class="
            py-2
            px-4
            flex flex-col
            sm:flex-row sm:space-x-3
            space-x-0
            sm:space-y-0
            space-y-3
            sm:items-center
            items-start
            justify-between
            hover:bg-gray-100
            border-b border-gray-200
          "
          :class="{ 'border-t': index == 0 }"
        >
          <div class="flex-1 flex items-left items-center space-x-5">
            <app-icon-jpg
              v-if="
                document.mime_type.includes('jpeg') ||
                document.mime_type.includes('jpg')
              "
            />
            <app-icon-png v-else-if="document.mime_type.includes('png')" />
            <app-icon-pdf v-else-if="document.mime_type.includes('pdf')" />
            <p class="text-sm">
              {{ document.file_name }}
            </p>
          </div>

          <div class="flex space-x-5">
            <p class="text-xs text-gray-500 mt-1">
              {{ Math.round(document.size / 1024) }} KB
            </p>
            <a
              :href="document.original_url"
              class="flex space-x-1 items-center cursor-pointer text-black"
              target="_blank"
            >
              <p class="text-sm">{{ $t("general.view") }}</p>
            </a>
            <a
              class="flex space-x-1 items-center cursor-pointer text-black"
              @click="download(document)"
            >
              <p class="text-sm">{{ $t("general.download") }}</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import BUSINESS_STATUS from "@/utils/const/business_status";
import PAYMENT_METHODS from "@/utils/const/payment_methods";
export default {
  data() {
    return {
      max: 5,
      files: {},
      unsaved: false,
      select_payment_method: null,
      showSelectPaymentMethod: true,
      PAYMENT_METHODS: PAYMENT_METHODS,
      BUSINESS_STATUS: BUSINESS_STATUS,
    };
  },
  computed: {
    loading() {
      return this.$store.getters["masterBusinessStore/loading"];
    },
    mediaLoading() {
      return this.$store.getters["mediaStore/loading"];
    },
    errors() {
      return this.$store.getters["masterBusinessStore/errors"];
    },
    business() {
      return this.$store.getters["masterBusinessStore/business"];
    },
    support_documents() {
      return this.$store.getters["masterBusinessStore/support_documents"];
    },
    type_support_documents() {
      return this.$store.getters["masterBusinessStore/type_support_documents"];
    },
    validate_support_documents() {
      return this.$store.getters[
        "masterBusinessStore/validate_support_documents"
      ];
    },
    isHasBusinessSupportDocuments() {
      return !this.$lodash.isEmpty(this.support_documents);
    },
    rule() {
      return !this.$lodash.isEmpty(this.select_payment_method)
        ? this.select_payment_method
            .map((payment_method) => {
              return payment_method.id;
            })
            .includes(PAYMENT_METHODS.CREDIT_CARD)
          ? "all"
          : "required"
        : null;
    },
    business_id() {
      return this.$route.query.business_id;
    },
    disabled() {
      return !(
        this.business.business_status_id == BUSINESS_STATUS.CREATED ||
        this.business.business_status_id == BUSINESS_STATUS.AMENDED
      );
    },
  },
  methods: {
    async submitSupportDocument() {
      this.resetState();

      const result = await this.$store.dispatch(
        "masterBusinessStore/createOrUpdateBusinessSupportDocument",
        {
          business_id: this.business_id,
          documents: this.files,
        }
      );

      if (result.data != null) {
        this.refresh();
        this.$router.push({
          path: "/master-business/business/summary",
          query: {
            business_id: this.business_id,
          },
        });
      } else if (result.message) {
        this.$notify(
          {
            group: "error",
            title: result.message,
          },
          5000
        );
      }
    },
    async deleteDocument(document) {
      const confirm_delete = confirm(
        this.$t("business.support_documents.delete_confirm")
      );

      if (confirm_delete) {
        const result = await this.$store.dispatch(
          "masterBusinessStore/deleteBusinessSupportDocument",
          {
            business_id: this.business_id,
            document_id: document._id,
          }
        );
      }

      this.refresh();
    },
    download(media) {
      axios
        .get(media.original_url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: media.mime_type });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = media.file_name;
          link.click();
        })
        .catch((error) => {
          // if has been blocked by CORS redirect to original url
          window.location.href = media.original_url;
        });
    },
    changed(file, title) {
      this.unsaved = true;
      Object.assign(this.files, { [title]: file });
    },
    resetState() {
      this.unsaved = false;
      this.$store.commit("masterBusinessStore/setErrorMessage");
    },
    refresh() {
      this.$store.dispatch(
        "masterBusinessStore/getBusinessSupportDocuments",
        this.business_id
      );
      this.$store.dispatch(
        "masterBusinessStore/validateBusinessSupportDocuments",
        this.business_id
      );
      this.$store.dispatch(
        "masterBusinessStore/typeBusinessSupportDocuments",
        this.business_id
      );
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.unsaved) {
      const confirm = window.confirm(
        this.$t("general.unsaved_changes_warning")
      );
      confirm ? next() : next(false);
    } else {
      next();
    }
  },
};
</script>